import {
  Box,
  Image,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from "@chakra-ui/react";
import { HiMenu, HiChevronDown } from "react-icons/hi";
import { useEffect, useState } from "react";

import { useAuth } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";

export const NavBar = ({ children }: { children: React.ReactNode }) => {
  const [currentLocation, setCurrentLocation] = useState(false);
  const { user, logout } = useAuth();
  const location = useLocation();

  const handleLoginLocation = () => {
    if (location.pathname === "/login") {
      setCurrentLocation(true);
    } else {
      setCurrentLocation(false);
    }
  };
  useEffect(() => {
    handleLoginLocation();
  }, [location]);

  return (
    <>
      {currentLocation ? (
        <Box width="100%" overflowX="clip">
          {children}
        </Box>
      ) : (
        <Box bg="gray.100" width="100%" minH="100vh" overflowX="clip">
          <Box
            as="nav"
            bg="black"
            width="100%"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            px={{ base: 5, md: 20 }}
          >
            <Link to="https://coresight.com/">
              <Image
                pr={5}
                minWidth="150px"
                maxWidth="180px"
                src="https://coresight.com/wp-content/uploads/2019/04/logo-red.png"
                alt="logo"
              />
            </Link>

            <HStack gap="4">
              <Box display={{ base: "none", md: "block" }} color="white">
                {/* @ts-ignore */}
                <marquee>
                Coresight Research helps retail clients accelerate innovation and growth. We focus on disruption at the intersection of retail and technology – and help you understand what it means for your business.
                  {/* @ts-ignore */}
                </marquee>
              </Box>

              {user ? (
                <>
                  <Box display={{ base: "block", md: "none" }}>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HiMenu />}
                        variant="ghost"
                        color="white"
                        _active={{ color: "black" }}
                      />
                      <MenuList>
                        <MenuItem>Welcome {user.name}</MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box display={{ base: "none", md: "block" }}>
                    <Menu>
                      <MenuButton
                        variant="ghost"
                        color="white"
                        as={Button}
                        rightIcon={<HiChevronDown />}
                        _hover={{ color: "gray.400" }}
                        _active={{ color: "gray.200" }}
                      >
                        Welcome {user.name}
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </>
              ) : (
                <Button
                  as={Link}
                  to="/login"
                  borderRadius="0"
                  colorScheme="red"
                >
                  Login
                </Button>
              )}
            </HStack>
          </Box>

          <Box width="100%">{children}</Box>
          <Footer />
        </Box>
      )}
    </>
  );
};
