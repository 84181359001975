import {
  Box,
  Button,
  Collapse,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DateFilter } from "./DateFilter";
import { StoreFilter as StoreFilters } from "./StoreFilter";
import { LocationFilter } from "./LocationFilter";
import { useEffect, useState } from "react";

interface StoreFilterProps {
  onFilterChange: (
    type: "All" | "Clear" | "Add" | "Remove",
    name: string,
    values: string[]
  ) => void;
  onParameterChange: (name: string, values: string | number) => void;
}

export const StoreFilter = ({
  onFilterChange,
  onParameterChange,
}: StoreFilterProps) => {
  const [isDate, toggleDate] = useState(false);
  const [isStore, toggleStore] = useState(false);
  const [isLocation, toggleLocation] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);

  useEffect(() => {
    const awaitLoad = setTimeout(() => {
      setLoadingFilters(true);
    }, 3000);

    return () => {
      clearTimeout(awaitLoad);
    };
  }, []);

  return (
    <Box width="full" pb={2}>
      <Stack direction="row" gap={3} width="full">
        <Stack gap={0} width={1 / 4}>
          <Text color="gray.500" fontSize="sm">
            Date
          </Text>
          <Button
            justifyContent="space-between"
            fontWeight="normal"
            variant="outline"
            width={["6em", "full"]}
            onClick={() => {
              toggleStore(false);
              toggleLocation(false);
              toggleDate(!isDate);
            }}
            bg="white"
            _hover={{ bg: "whiteAlpha.500" }}
          >
            {loadingFilters ? "Date" : "Loading ..."}
          </Button>
        </Stack>

        <Stack gap={0} width={1 / 4}>
          <Text color="gray.500" fontSize="sm">
            Store
          </Text>
          <Button
            justifyContent="space-between"
            fontWeight="normal"
            variant="outline"
            width={["6em", "full"]}
            onClick={() => {
              toggleLocation(false);
              toggleDate(false);
              toggleStore(!isStore);
            }}
            bg="white"
            _hover={{ bg: "whiteAlpha.500" }}
          >
            {loadingFilters ? "Store" : "Loading ..."}
          </Button>
        </Stack>
        <Stack gap={0} width={1 / 4}>
          <Text color="gray.500" fontSize="sm">
            Location
          </Text>
          <Button
            justifyContent="space-between"
            fontWeight="normal"
            variant="outline"
            width={["7em", "full"]}
            onClick={() => {
              toggleStore(false);
              toggleDate(false);
              toggleLocation(!isLocation);
            }}
            bg="white"
            _hover={{ bg: "whiteAlpha.500" }}
          >
            {loadingFilters ? "Location" : "Loading ..."}
          </Button>
        </Stack>
      </Stack>

      <Collapse in={isDate} animateOpacity>
        <Stack
          direction={["column", "row"]}
          mt="4"
          bg="white"
          rounded="sm"
          shadow="md"
          width="full"
          p={{ base: 2, md: 3 }}
        >
          <DateFilter onChange={onParameterChange} />
        </Stack>
      </Collapse>
      <Collapse in={isStore} animateOpacity>
        <StoreFilters onFilterChange={onFilterChange} />
      </Collapse>
      <Collapse in={isLocation} animateOpacity>
        <LocationFilter onFilterChange={onFilterChange} />
      </Collapse>
    </Box>
  );
};
