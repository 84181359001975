import { Heading, Stack, Text } from "@chakra-ui/react";
import { PageNav } from "../../components/PageNav";
import {
  HOME_PAGE,
  COMPARISONS_PAGE,
  STORE_TRACKER_PAGE,
} from "../../config/pageRoutes";
import Dashboards from "./Dashboards/Dashboards";

export const ComparisonsPage = () => {
  return (
    <Stack bg="white" px="10" width="100%" gap={5} pt="2">
      <PageNav
        links={[
          { title: "Home", path: HOME_PAGE },
          {
            title: "Store Intelligence",
            path: STORE_TRACKER_PAGE,
          },
          {
            title: "Comparisons",
            active: true,
            path: COMPARISONS_PAGE,
          },
        ]}
      />
      <Heading fontSize="3xl">Comparison Dashboards</Heading>
      <Text color="GrayText">Compare Stores</Text>
      <Dashboards />
    </Stack>
  );
};
