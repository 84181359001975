import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
  VStack,
  Stack,
} from "@chakra-ui/react";
import Select from "react-select";
//import {OnChangeValue} from "react-select";
import { ChangeEvent, FormEvent, useRef, useState, useEffect } from "react";
import { makeRequest } from "../api/exportRequest";
import csvDownload from "json-to-csv-export";

interface storeType {
  label: string;

  value: string;
}

type bannerType = {
  label: string;
  value: string;
}[];

const storeDataType_options = [
  {
    label: "Store Openings",

    value: "opened",
  },

  {
    label: "Store closings",

    value: "closed",
  },

  {
    label: "Active stores",

    value: "active",
  },
];

export default function SendRequestForm() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [bannerNames, setBannerNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [categoryNames, setCategoryNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [parentNames, setParentNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [storeNames, setStoreNames] = useState<
    { label: string; value: string }[]
  >([]);

  const [dataRequest, setDataRequest] = useState({
    startDate: "",
    endDate: "",
    storeDataType: "",
    bannerName: "",
    parentName: "",
  });

  const [selectedParents, setSelectedParents] = useState([""]);
  const [callHandleBanner, setCallHandleBanner] = useState(false);
  const initialRender = useRef(true);

  function makeStoreTypeRequired() {
    const dropdown = document.getElementById(
      "react-select-2-input"
    ) as HTMLInputElement | null;
    dropdown?.setAttribute("required", "required");
  }

  function makeBannerRequired() {
    const dropdown = document.getElementById(
      "react-select-4-input"
    ) as HTMLInputElement | null;
    dropdown?.setAttribute("required", "required");
  }

  function removeStoreTypeRequired() {
    const dropdown = document.getElementById(
      "react-select-2-input"
    ) as HTMLInputElement | null;
    dropdown?.removeAttribute("required");
  }

  function removeBannerRequired() {
    const dropdown = document.getElementById(
      "react-select-4-input"
    ) as HTMLInputElement | null;
    dropdown?.removeAttribute("required");
  }

  function loadCategoryNames() {
    const categories = JSON.parse(localStorage.getItem("categoryName") || "");
    setCategoryNames(categories);
  }
  function loadParentNames() {
    const parents = JSON.parse(localStorage.getItem("parentBannerName") || "");
    setParentNames(parents);
  }
  function loadStoreNames() {
    const stores = JSON.parse(localStorage.getItem("storeName") || "");
    setStoreNames(stores);
  }

  function loadBannerNames() {
    const bannerNames = JSON.parse(localStorage.getItem("bannerName") || "");
    if (selectedParents[0] === "" || selectedParents.length === 0) {
      setBannerNames(bannerNames);
    } else {
      const bannerNames = JSON.parse(
        localStorage.getItem("filterBannerNames") || ""
      );
      let filterArr: any[] = [];
      for (let i = 0; i < selectedParents.length; i++) {
        const filteredBanner = bannerNames.filter(
          (item: any) => item.parentbannername === selectedParents[i]
        );
        filteredBanner.forEach((item: any) => {
          filterArr.push(item);
        });
      }

      const bannerNamesArr = filterArr.map((data) => data.bannername);
      const bannerOptions = bannerNamesArr.map((item: any) => ({
        label: item,
        value: item,
      }));
      setBannerNames(bannerOptions);
    }
  }

  const handleParentName = (option: any) => {
    console.log(option, "JSON");
    const selectedOption: bannerType = option as bannerType;
    let arrayOption = selectedOption.map((option) => {
      return option.value;
    });

    setSelectedParents(arrayOption);

    let combineOption = arrayOption.join(",");

    combineOption = combineOption.replace("&", "%26");

    setDataRequest({
      ...dataRequest,
      parentName: combineOption,
    });

    setCallHandleBanner(!callHandleBanner);

    console.log(dataRequest);
  };

  const handleBannerChange = (option: any) => {
    console.log(option, "JSON");
    if (option.length !== 0) {
      removeBannerRequired();
    } else {
      makeBannerRequired();
    }

    const selectedOption: bannerType = option as bannerType;
    let arrayOption = selectedOption.map((option) => {
      return option.value;
    });

    let combineOption = arrayOption.join(",");

    combineOption = combineOption.replace("&", "%26");

    setDataRequest({
      ...dataRequest,
      bannerName: combineOption,
    });

    console.log(dataRequest);
  };

  const handleStoreData = (option: any) => {
    console.log(option, "JSON");
    if (option.value !== null) {
      removeStoreTypeRequired();
    } else {
      makeStoreTypeRequired();
    }

    const selectedOption: storeType = option as storeType;

    setDataRequest({ ...dataRequest, storeDataType: selectedOption.value });

    // console.log("Banner Name VALUE: ", selectedOption.value);
  };

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target;
    setDataRequest({ ...dataRequest, [name]: value });
  };

  // const handleSelectChange1 = (option: any ) => {
  //   console.log(option, 'JSON');
  //   const selectedOption: bannerType = option as bannerType;
  //   setDataRequest({ ...dataRequest, "storeName": selectedOption.value })
  //   console.log('VALUE: ', selectedOption.value);
  // }

  // const handleSelectChange2 = (option: any ) => {
  //   console.log(option);
  //   //let selectedOption = option as bannerType;
  //   //setDataRequest({ ...dataRequest, "storeDataType": selectedOption.options.name });
  // }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    sendRequest(
      dataRequest.storeDataType,
      dataRequest.bannerName,
      dataRequest.startDate,
      dataRequest.endDate,
      dataRequest.parentName
    );
    console.log(dataRequest);

    setLoading(false);
    toast({
      title: "Data Request Sent",
      status: "success",
      isClosable: true,
      duration: 9000,
    });
  };

  async function sendRequest(
    storeDataType: string,
    bannerName: string,
    startDate: string,
    endDate: string,
    parentName: string
  ) {
    const response = await makeRequest(
      storeDataType,
      bannerName,
      startDate,
      endDate,
      parentName
    );
    console.log(
      storeDataType + " " + bannerName + " " + startDate + " " + endDate
    );
    console.log(response.next);

    csvDownload({
      data: response.results,
      filename: "export.csv",
      delimiter: ",",
    });
  }

  //const newArray = bannerNames.map(item => ({label: item, value: item }))

  useEffect(() => {
    const awaitCall = setTimeout(() => {
      loadParentNames();
      loadBannerNames();
      makeBannerRequired();
      makeStoreTypeRequired();
    }, 2000);

    return () => {
      clearTimeout(awaitCall);
    };
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      loadBannerNames();
    }
  }, [callHandleBanner]);

  return (
    <Box width="full" bg="gray.50" py={10}>
      <VStack gap={3}>
        <Heading
          size="lg"
          fontWeight="normal"
          id="submit-request-header"
          marginBottom="1em"
        >
          Download Data
        </Heading>

        <Box width={["80%", null, "50%"]}>
          <form
            id="storefront-request-form"
            onSubmit={handleSubmit}
            // style={{ width: "inherit" }}
          >
            <Stack
              direction={["column", "row"]}
              spacing="24px"
              width="full"
              mb={5}
            >
              <FormControl size="lg" isRequired>
                <FormLabel>Start Date</FormLabel>
                <Input
                  isRequired
                  onChange={handleChange}
                  name="startDate"
                  placeholder="Select date"
                  type="date"
                  bg="white"
                />
              </FormControl>
              <FormControl size="lg" isRequired>
                <FormLabel>End Date</FormLabel>
                <Input
                  isRequired
                  onChange={handleChange}
                  name="endDate"
                  placeholder="Select date"
                  type="date"
                  bg="white"
                />
              </FormControl>
            </Stack>
            <Stack
              direction={["column", "row"]}
              spacing="24px"
              width="full"
              mb={5}
            >
              <FormControl size="lg" isRequired>
                <FormLabel>Store Data Type</FormLabel>
                {/* <Select options={storeDataType_options} isSearchable={false}/> */}
                <Select
                  options={storeDataType_options}
                  onChange={handleStoreData}
                  menuPlacement="top"
                />
              </FormControl>

              <FormControl size="lg">
                <FormLabel>Parent Banners</FormLabel>

                <Select
                  options={parentNames}
                  onChange={handleParentName}
                  isMulti
                  menuPlacement="top"
                />
              </FormControl>

              <FormControl size="lg" isRequired>
                <FormLabel>Banners</FormLabel>

                <Select
                  options={bannerNames}
                  onChange={handleBannerChange}
                  isMulti
                  menuPlacement="top"
                />
              </FormControl>
            </Stack>

            <Stack direction="row" justifyContent="center">
              <Button
                isLoading={loading}
                loadingText="Sending data request..."
                colorScheme="red"
                type="submit"
              >
                Download
              </Button>
            </Stack>
          </form>
        </Box>
      </VStack>
    </Box>
  );
}
