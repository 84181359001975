import { Link } from "@chakra-ui/react";

export default function FooterLinks({
  title,
  link,
}: {
  title: string;
  link: string;
}) {
  return (
    <Link
      target="_blank"
      display="block"
      color="white"
      _active={{ bg: "red" }}
      href={link}
    >
      {title}
    </Link>
  );
}
