import axios from "axios";

import {
  ICategoriesResponse,
  IBannerNamesResponse,
  IMsaNamesResponse,
  IParentNamesResponse,
  IStateRequestParams,
  IStatesResponse,
  IStoreNamesResponse,
  IStoreRequestParams,
} from "../types/DashboardFilters";

const BASE_URL = "https://sip-api.coresight.com";

const getQueryString = (
  params: IStoreRequestParams | IStateRequestParams
): string => {
  const paramsArray = Object.entries(params);

  if (!paramsArray.length) return "";

  return paramsArray.reduce(
    (query, [key, value]) => `${query}&${key}__in=${value}`,
    "?"
  );
};

export const getStoreNames = async (
  params: IStoreRequestParams
): Promise<IStoreNamesResponse> => {
  const response = await axios.get(
    `${BASE_URL}/stores?limit=100${getQueryString(params)}`
  );
  return response.data;
};

export const getCategories = async (
  params: IStoreRequestParams
): Promise<ICategoriesResponse> => {
  const response = await axios.get(
    `${BASE_URL}/categories?limit=300${getQueryString(params)}`
  );
  return response.data;
};

export const getBannerNames = async (
  params: IStoreRequestParams
): Promise<IBannerNamesResponse> => {
  const response = await axios.get(
    `${BASE_URL}/chainnames?limit=500${getQueryString(params)}`
  );
  return response.data;
};

export const getSingleBannerNames = async (
  params: IStoreRequestParams
): Promise<IBannerNamesResponse> => {
  const response = await axios.get(
    "https://sip-api.coresight.com/chainnames?&parentbannername=&limit=500"
  );
  return response.data;
};

export const getParentNames = async (
  params: IStoreRequestParams
): Promise<IParentNamesResponse> => {
  const response = await axios.get(
    `${BASE_URL}/parentnames?limit=500${getQueryString(params)}`
  );
  return response.data;
};

export const getMsaNames = async (
  params: IStateRequestParams
): Promise<IMsaNamesResponse> => {
  const response = await axios.get(
    `${BASE_URL}/msanames${getQueryString(params)}`
  );

  response.data.forEach((item: any) => {
    if(item.msaname.indexOf(',') > 0) item.msaname = item.msaname.substring(0, item.msaname.indexOf(',')) +", "+ item.state; 
    else item.msaname = item.msaname +", "+ item.state;
  });

  return response.data;
};

export const getStates = async (
  params: IStateRequestParams
): Promise<IStatesResponse> => {
  const response = await axios.get(
    `${BASE_URL}/states?limit=300${getQueryString(params)}`
  );
  return response.data;
};

export const getLocations = async (
  params: IStateRequestParams
): Promise<IStatesResponse> => {
  const response = await axios.get(
    "https://sip-api.coresight.com/states?&limit=500"
  );
  return response.data;
};
