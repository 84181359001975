import { StoreFilter } from "../pages/StoreIntelligence/components/Filters";

export const baseDashboardConfigs = {
  opening: {
    url: "https://public.tableau.com/views/Open_Stores_Staging/StoreOpeningStaging?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    containerID: "store_opening_dashboard",
    colorScheme: "rgb(0, 104, 253)",
    title: "Store Openings (Base Dashboard)",
    redirectPath: "/comparisons/0",
    redirectTitle: "Compare Retailers",
  },

  closing: {
    url: "https://public.tableau.com/views/Closed_Stores_Staging/StagingClosedStores?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    containerID: "store_closing_dashboard",
    colorScheme: "rgb(214, 46, 47)",
    title: "Store Closures  (Base Dashboard)",
    redirectPath: "/comparisons/1",
    redirectTitle: "Compare Retailers",
  },

  active: {
    url: "https://public.tableau.com/views/ActiveDashboard/ActiveStoresStaging?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    containerID: "active_stores_dashboard",
    colorScheme: "black",
    title: "Active Stores (Base Dashboard)",
    redirectPath: "/comparisons/2",
    redirectTitle: "Compare Retailers",
  },
};

export const comparisonDashboardConfigs = {
  opening: {
    url: "https://public.tableau.com/views/Stagingopeningcomparison/StagingOpencomaprism?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    containerID: "store_opening_dashboard",
    colorScheme: "rgb(0, 104, 253)",
    title: "Store Openings (Comparison Dashboard)",
    redirectPath: "/storetracker/0",
    redirectTitle: "Base Dashboard",
  },

  closing: {
    url: "https://public.tableau.com/views/Stagingclosingcomparism/Stagingclosecomparism?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    containerID: "store_closing_dashboard",
    colorScheme: "rgb(214, 46, 47)",
    title: "Store Closures (Comparison Dashboard)",
    redirectPath: "/storetracker/1",
    redirectTitle: "Base Dashboard",
  },

  active: {
    url: "https://public.tableau.com/views/ActiveStoresBaseDashboard_16575581087720/ActiveStoreComparism?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    containerID: "active_stores_dashboard",
    colorScheme: "black",
    title: "Active Stores  (Comparison Dashboard)",
    redirectPath: "/storetracker/2",
    redirectTitle: "Base Dashboard",
  },
};

export interface IDashboardConfig {
  title: string;
  src: string;
  filters: any;
}

export const storeOpening: IDashboardConfig[] = [
  {
    title: "Base Dashboard",
    src: "https://public.tableau.com/views/StoreOpeningProdDashboard/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },

  {
    title: "Compare Retailers",
    src: "https://public.tableau.com/views/StoreOpeningComparisonProdDashboard/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },
];

export const storeClosing = [
  {
    title: "Base Dashboard",
    src: "https://public.tableau.com/views/StoreClosureProductionDashboard/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },

  {
    title: "Compare Retailers",
    src: "https://public.tableau.com/views/StoreClosureComparismProdDashboard/Dashboard1?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },
];

export const activeStores = [
  {
    title: "Base Dashboard",
    src: "https://public.tableau.com/views/ActiveStoresProdDashboard2/ActiveStoresDashboard?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },

  {
    title: "Compare Retailers",
    src: "https://public.tableau.com/views/ActiveStoresComparismProdDashboard/ActiveStoresDashboard?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },

  {
    title: "Industry Trends",
    src: "https://public.tableau.com/views/IndustryTrendProdDashboard/ActiveStoresDashboard?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link",
    filters: StoreFilter,
  },
];
