import { Tabs, TabList, Tab, Box } from "@chakra-ui/react";
import { ReactNode, useState } from "react";

interface DashboardTabsProps {
  tabs: Array<{ title: string; content: ReactNode }>;
}

export default function DashboardTabs({ tabs }: DashboardTabsProps) {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <Box>
        <Tabs
          colorScheme="red"
          index={currentTab}
          onChange={(index) => setCurrentTab(index)}
          size={["sm", null, null, "md"]}
        >
          <TabList>
            {tabs.map(({ title }) => (
              <Tab key={title}>{title}</Tab>
            ))}
          </TabList>
        </Tabs>
        <Box mt={3}>
          {tabs.map(({ content }, index) => index === currentTab && content)}
        </Box>
      </Box>
    </>
  );
}
