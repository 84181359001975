import { useState, useEffect } from "react";

function useLocalStorage(itemKey: string, itemValue: any) {
  const [value, setValue] = useState(itemValue);
  useEffect(() => {
    window.localStorage.setItem(itemKey, JSON.stringify(value));
  }, [itemKey, value]);
  return [value, setValue];
}

export default useLocalStorage;
