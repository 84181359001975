import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as authAPI from "../api/authentication";
import { LOGIN_PAGE } from "../config/pageRoutes";
import { initializePendo } from "../services/pendo";

type AuthContextType = {
  user: any;
  login: (email: string) => Promise<void>;
  logout: () => void;
  // isAdmin: () => boolean;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export default function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<any | null>(null);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  // async function login(email: string, password: string) {
  //   const response = await authAPI.login(email, password);
  //   const user = await authAPI.getLoggedInUser(response.token);
  //   setUser({ email, name: email });

  //   initializePendo(user.email);

  //   localStorage.setItem("coresight-token", response.token);
  // }

  async function logout() {
    setUser(null);
    localStorage.removeItem("coresight-token");
    navigate(LOGIN_PAGE, { replace: true, state: { from: location } });
  }

  // async function getCurrentUser() {
  //   const token = localStorage.getItem("coresight-token");
  //   if (!token) return setLoadingInitial(false);
  //   try {
  //     const response = await authAPI.getLoggedInUser(token);
  //     setUser({ ...response, name: response.email });
  //     setLoadingInitial(false);
  //   } catch (err) {
  //     setLoadingInitial(false);
  //   }
  // }

  async function getCurrentUser() {
    const token = localStorage.getItem("coresight-token");
    if (!token) return setLoadingInitial(false);
    try {
      await login(token);
      setLoadingInitial(false);
    } catch (err) {
      setLoadingInitial(false);
    }
  }

  async function login(email: string) {
    const response = await authAPI.loginViaMemberPress(email);
    if (response.error) {
      throw new Error(response.error);
    }
    const { first_name, username, active_memberships } = response.data;
    setUser({ name: first_name || username });

    let membership = active_memberships.find(
      (membership: any) =>
        membership.title === "Paid Member" || membership.title === "Employee"
    );
    if (!membership) membership = "Employee";

    initializePendo({ email, membership });
    localStorage.setItem("coresight-token", email);
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  const memoedValue = useMemo(
    () => ({
      user,
      // isAdmin,
      login,
      logout,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
