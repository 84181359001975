import { useParams } from "react-router-dom";
import DashboardTabs from "../../../components/Dashboard/DashboardTabs";
import ActiveStoresComparisonDashboard from "./Active";
import StoreClosingDashboard from "./Closing";
import StoreOpeningDashboard from "./Opening";

export default function Dashboards() {
  const { id }: any = useParams();
  console.log("Iddu4irhked", id);

  const dashboards = [
    <StoreOpeningDashboard />,
    <StoreClosingDashboard />,
    <ActiveStoresComparisonDashboard />,
  ];
  const titles = ["Store Openings", "Store Closures", "Active Stores"];
  return (
    <DashboardTabs
      titles={titles}
      contents={dashboards}
      activeTab={Number(id)}
    />
  );
}
