import { Heading, Box, Stack, VStack, Skeleton } from "@chakra-ui/react";
import SendRequestForm from "../../components/SubmitRequestForm";
import { activeStores } from "../../config/dashboardConfigs";
import Dashboard from "./components/Dashboard";
import { DashboardCard } from "./components/DashboardCard";
import DashboardFloatingMenu from "./components/DashboardFloatingMenu";
import { SmallerDashboardCard } from "./components/SmallerDashboardCard";
import DashboardTabs from "./components/Tabs";
import TestDashboard from "./components/testDashboard";

export const ActiveStoresPage = () => {
  const tabs = [
    {
      title: activeStores[0].title,
      content: (
        <>
          <TestDashboard
            containerID={activeStores[0].title + Math.random()}
            src={activeStores[0].src}
            Filters={activeStores[0].filters}
          />

          <SendRequestForm />

          <DashboardFloatingMenu
            jumpToFilterID="store-intelligence-platform-heading"
            jumpToDownloadID="submit-request-header"
          />
        </>
      ),
    },

    {
      title: activeStores[1].title,
      content: (
        <>
          <TestDashboard
            containerID={activeStores[1].title + Math.random()}
            src={activeStores[1].src}
            Filters={activeStores[1].filters}
            height={"1550px"}
          />

          <SendRequestForm />

          <DashboardFloatingMenu
            jumpToFilterID="store-intelligence-platform-heading"
            jumpToDownloadID="submit-request-header"
          />
        </>
      ),
    },
    {
      title: activeStores[2].title,
      content: (
        <>
          <TestDashboard
            containerID={activeStores[2].title + Math.random()}
            src={activeStores[2].src}
            Filters={activeStores[2].filters}
            height={"1250px"}
          />

          <SendRequestForm />

          <DashboardFloatingMenu
            jumpToFilterID="store-intelligence-platform-heading"
            jumpToDownloadID="submit-request-header"
          />
        </>
      ),
    },
  ];
  return (
    <>
      <Heading
        size={["sm", null, null, "md"]}
        as="h2"
        textTransform="capitalize"
      >
        Active Stores
      </Heading>
      <DashboardTabs tabs={tabs} />
    </>
  );
};
