import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";

function ComingSoon() {
  return (
    <Center background="#FFFFFF" width="100vw">
      <Flex
        width={["90%", null, "70%"]}
        height={["25em", "30em"]}
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        marginBottom="12em"
      >
        <Heading
          as="h3"
          color="#323232"
          marginTop="2em"
          lineHeight={["30px", null, "48px"]}
          fontSize={["20px", "30px", "32px"]}
          textAlign="center"
        >
          The Store Intelligence Platform is under maintenance.{" "}
          <Box as="br" display={["none", null, null, "block"]} /> We’ll be back
          soon!
        </Heading>

        <Image
          boxSize="500px"
          objectFit="contain"
          src="https://coresight.com/wp-content/uploads/2019/04/maintenance.jpg"
          alt="under maintenance"
        />

        <Heading size={["xs", "md"]} color="#323232">
          Sorry for the inconvenience but we’re performing some maintenance at
          the moment. If you need to you can always{" "}
          <Link
            href="mailto:contact@coresight.com"
            color="#ED1F2C"
            display="inline"
          >
            contact us
          </Link>{" "}
          , otherwise we’ll be back online shortly! —{" "}
          <Text fontWeight="500" display="inline">
            {" "}
            The Team At Coresight Research
          </Text>
        </Heading>
      </Flex>
    </Center>
  );
}

export default ComingSoon;
