import { HStack, Icon, Link } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { Link as BaseLink } from "react-router-dom";

type PageNavProps = {
  links: Array<{ title: string; active?: boolean; path: string }>;
};

export const PageNav = ({ links }: PageNavProps) => {
  return (
    <HStack>
      {links.map(({ title, active, path }) => (
        <HStack gap={0} color="GrayText" key={title}>
          <Link
            to={path}
            as={BaseLink}
            fontSize="sm"
            colorScheme="red"
            textDecor="none"
            color={active ? "red.600" : "gray"}
            _hover={{ color: "red.600" }}
          >
            {title}
          </Link>
          <Icon as={FiChevronRight} />
        </HStack>
      ))}
    </HStack>
  );
};
