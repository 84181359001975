import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export async function login(email: string, password: string) {
  const response = await axios.post(
    "http://stage.research.coresight.com/api/api-token-auth/",
    {
      username: email,
      password,
    }
  );
  return response.data;
}

export async function logout() {}

export async function getLoggedInUser(token: string) {
  const headers = {
    Authorization: `Token ${token}`,
  };
  const response = await axios.get(
    "http://stage.research.coresight.com/api/api-token-verify/",
    { headers }
  );
  return response.data;
}

export async function loginViaMemberPress(email: string) {
  const errorMessage =
    "You are not authorized to access this product please email contact@coresight.com";

  try {
    const { data } = await axios.get(
      `https://memberpress.herokuapp.com/?email=${email}`
    );
    const user = data[0];
    if (!user) return { status: "error", error: errorMessage };

    const isMember = user.active_memberships.find((membership: any) =>
      ["Employee", "Paid Member"].includes(membership.title)
    );
    const isCoresightEmployee = email.split("@")[1] === "coresight.com";

    if (isCoresightEmployee) return { status: "success", data: user };

    if (!isMember) return { status: "error", error: errorMessage };

    return { status: "success", data: user };
  } catch (err) {
    return { status: "error", error: errorMessage };
  }
}
