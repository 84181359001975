import RequireAuth from "../components/RequireAuth";
import { ComparisonsPage } from "../pages/Comparisons/Index";
import LandingPage from "../pages/LandingPage";
import { LoginPage } from "../pages/Login/Index";
import { ActiveStoresPage } from "../pages/StoreIntelligence/ActiveStorePage";
import { StoreClosingPage } from "../pages/StoreIntelligence/StoreClosingPage";
import StoreIntelligencePlatformPage from "../pages/StoreIntelligence/StoreInteligencePage";
import { StoreOpeningPage } from "../pages/StoreIntelligence/StoreOpeningPage";
import { StoreTrackerPage } from "../pages/StoreTracker/Index";
import { PageRoute } from "../types/routes";
import ComingSoon from "../pages/ComingSoon/ComingSoon";

export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const STORE_TRACKER_PAGE = "/storetracker";
export const COMPARISONS_PAGE = "/comparisons";
export const STORE_INTELIGENCE_PAGE = "/storeintelligence";
export const INITIAL_PAGE_AFTER_LOGIN = "/storeintelligence/opening";

export const pageRoutes: PageRoute[] = [
  { path: HOME_PAGE, element: <ComingSoon /> },
  { path: LOGIN_PAGE, element: <LoginPage /> },
  {
    path: STORE_TRACKER_PAGE,
    element: (
      <RequireAuth>
        <StoreTrackerPage />
      </RequireAuth>
    ),
  },
  {
    path: `${STORE_TRACKER_PAGE}/:id`,
    element: (
      <RequireAuth>
        <StoreTrackerPage />
      </RequireAuth>
    ),
  },
  {
    path: COMPARISONS_PAGE,
    element: (
      <RequireAuth>
        <ComparisonsPage />
      </RequireAuth>
    ),
  },
  {
    path: `${COMPARISONS_PAGE}/:id`,
    element: (
      <RequireAuth>
        <ComparisonsPage />
      </RequireAuth>
    ),
  },

  {
    path: `${STORE_INTELIGENCE_PAGE}`,
    element: (
      <RequireAuth>
        <StoreIntelligencePlatformPage />
      </RequireAuth>
    ),
    subRoutes: [
      { path: "opening", element: <StoreOpeningPage /> },
      { path: "closing", element: <StoreClosingPage /> },
      { path: "active", element: <ActiveStoresPage /> },
    ],
  },
  {
    path: "*",
    element: (
      <RequireAuth>
        <StoreTrackerPage />
      </RequireAuth>
    ),
  },
];
