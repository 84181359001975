import { HStack, Heading, IconButton, Button, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FiExternalLink, FiDownload } from "react-icons/fi";

interface DashboardHeaderProps {
  title: string;
  onDownload: () => void;
  redirectPath?: string;
  redirectTitle?: string;
  backgroundColor: string;
  loadedDashboard?: boolean;
}
export default function DashboardHeader({
  title,
  onDownload,
  redirectPath,
  redirectTitle,
  backgroundColor,
  loadedDashboard,
}: DashboardHeaderProps) {
  return (
    <Box
      width="101%"
      pt={12}
      background="white"
      position="absolute"
      zIndex={1}
      left={-5}
    >
      <HStack
        p={4}
        bg={backgroundColor}
        justify="space-between"
        alignItems="center"
        width="full"
      >
        <Heading color="white" size="md" as="h2">
          {title}
        </Heading>
        <HStack>
          <IconButton
            icon={<FiDownload />}
            onClick={onDownload}
            aria-label="download dashboard"
            isDisabled={!loadedDashboard}
            title="Download Dashboard"
          />
          {redirectPath && (
            <Button
              fontWeight="normal"
              rightIcon={<FiExternalLink />}
              as={Link}
              to={redirectPath}
              replace={true}
            >
              {redirectTitle}
            </Button>
          )}
        </HStack>
      </HStack>
    </Box>
  );
}
