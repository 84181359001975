import { Box, Button, Stack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { useBreakpointValue } from "@chakra-ui/react";
const { tableau } = window;

interface IViz {}

interface DashboardProps {
  src: string;
  containerID: string;
  Filters?: any;
  height?: any;
  hideLogoMargin?: any;
}

export default function TestDashboard({
  src,
  containerID,
  Filters,
  height = "2700px",
  hideLogoMargin = "-3em",
}: DashboardProps) {
  const [viz, setViz] = useState<any>();

  const ref = useRef(null);
  const getScreenSize = useBreakpointValue(
    { base: "sm", md: "md" },
    { ssr: false }
  );
  const [screenSize, setScreenSize] = useState(getScreenSize);

  const initViz = () => {
    let containerDiv = document.getElementById("container");
    console.log(getScreenSize);

    const device = getScreenSize === "md" || undefined ? "desktop" : "mobile";

    const options = {
      device: device,
      hideTabs: true,
      width: "100%",
      height: "100%",
    };
    if (containerDiv!.childNodes?.length < 1) {
      const viz = new tableau.Viz(ref.current, src, options);
      setViz(viz);
    }
  };

  async function showDownloadDialog() {
    viz.showExportImageDialog();
  }

  async function applyFilter(
    type: "All" | "Clear" | "Add" | "Remove",
    name: string,
    values?: string[]
  ) {
    console.log("Type", type);
    console.log("Values", values);
    console.log("Name", name);

    const sheet = viz.getWorkbook().getActiveSheet();
    if (type === "All") {
      sheet.applyFilterAsync(name, "", tableau.FilterUpdateType.ALL);
      console.log("All");
    } else if (type === "Add") {
      !!values?.length &&
        sheet.applyFilterAsync(name, values, tableau.FilterUpdateType.ADD);
      console.log("Add");
    } else if (type === "Remove" && values!.length < 1) {
      /* !!values?.length && */
      sheet.applyFilterAsync(name, "All", tableau.FilterUpdateType.REPLACE);
      console.log("Remove and length < 1");
    } else if (type === "Remove" && values!.length < 50) {
      !!values?.length &&
        sheet.applyFilterAsync(name, values, tableau.FilterUpdateType.REMOVE);
      console.log("Remove and length < 50");
    } else if (type === "Remove" && values!.length > 50) {
      !!values?.length &&
        sheet.applyFilterAsync(name, values, tableau.FilterUpdateType.REPLACE);
      console.log("Remove and length > 50");
    } else if (type === "Clear") {
      sheet.applyFilterAsync(name, "All", tableau.FilterUpdateType.REPLACE);
      console.log("Clear");
    }
  }
  async function applyDate(
    startMonth: any,
    startYear: any,
    endMonth: any,
    endYear: any
  ) {
    const sheet = viz.getWorkbook();
    // console.log("startMONTH", startMonth);
    // console.log("startYear", startYear);
    // console.log("endMONTH", endMonth);
    // console.log("endyEAR", endYear);

    sheet.changeParameterValueAsync("Start Month", startMonth);
    sheet.changeParameterValueAsync("Start Year", startYear);
    sheet.changeParameterValueAsync("End Month", endMonth);
    sheet.changeParameterValueAsync("End Year", endYear);
  }

  useEffect(() => {
    initViz();
  }, []);

  return (
    <Stack position="relative" width="full">
      <Button
        display={["none", null, "block"]}
        position="absolute"
        right={0}
        top={-12}
        color="#023877"
        variant="ghost"
        leftIcon={<FiDownload />}
        colorScheme="whiteAlpha"
        onClick={showDownloadDialog}
      >
        Download Report
      </Button>
      <Stack direction="row">
        <Filters onFilterChange={applyFilter} onParameterChange={applyDate} />
      </Stack>

      <Box width="full" position="relative">
        <Box
          position="relative"
          id="container"
          // width="1250px"
          width="100%"
          height={["2000px", null, null, `${height}`]}
          ref={ref}
        />

        <Box
          position="relative"
          top={["-3em", null, null, `${hideLogoMargin}`]}
          id="container"
          width="full"
          height="30px"
          background={["transparent", null, null, "#EDF2F7"]}
        />
      </Box>
    </Stack>
  );
}
