import { Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  getCategories,
  getBannerNames,
  getParentNames,
} from "../../../api/filters";
import { MultiSelectInput } from "./MultiSelectInput";
import useLocalStorage from "../../../hooks/useLocalStorage";

interface StoreFilterProps {
  onFilterChange: (
    type: "All" | "Clear" | "Add" | "Remove",
    name: string,
    values: string[]
  ) => void;
}
const StoreFilterMemo = ({ onFilterChange }: StoreFilterProps) => {
  const [parentNames, setParentNames] = useState<string[]>([]);
  const [bannerNames, setBannerNames] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [parentSearchParam, setParentSearchParam] = useState("");
  const [bannerSearchParam, setBannerSearchParam] = useState("");
  const [categorySearchParam, setCategorySearchParam] = useState("");
  const [disabledFilters, setDisabledFilters] = useState({
    parentNames: false,
    categories: false,
    bannerNames: false,
  });

  const [parentNameDDF, setParentNameDDF] = useLocalStorage(
    "parentBannerName",
    []
  );
  const [bannerNameDDF, setBannerNameDDF] = useLocalStorage("bannerName", []);

  const [bannerNameDFS, setBannerNameDFS] = useLocalStorage(
    "filterBannerNames",
    []
  );

  const [loadingParentNames, setLoadingParentNames] = useState(false);
  const loadParentCompanies = async () => {
    await getParentNames({
      parentbannername: "",
    }).then((res: any) => {
      // Items for dashboard Filters
      const convertToArrOfStr = res.map(
        (data: { parentbannername: any }) => data.parentbannername
      );
      setParentNames(convertToArrOfStr);

      // Items for Download data form filters
      const parentBannerNamesArr = res.map(
        (data: { parentbannername: any }) => data.parentbannername
      );
      const parentBannerNames = parentBannerNamesArr.map((item: any) => ({
        label: item,
        value: item,
      }));
      setParentNameDDF(parentBannerNames);
    });
  };

  const [loadingBannerNames, setLoadingBannerNames] = useState(false);
  const loadBannerNames = async (selectedParents: string[]) => {
    console.log("selectedParents", selectedParents);
    setLoadingBannerNames(true);
    await getBannerNames({
      bannername: "",
    }).then((res) => {
      // Items for dashboard Filters
      const convertToArrOfStr = res.map(
        (data: { bannername: any }) => data.bannername
      );
      //Items for dashboard filters sorting
      setBannerNameDFS(res);
      // Items for Download data form filters
      const bannerNamesArr = res.map(
        (data: { bannername: any }) => data.bannername
      );
      const bannerNames = bannerNamesArr.map((item: any) => ({
        label: item,
        value: item,
      }));
      setBannerNameDDF(bannerNames);

      const filterBannerNames = JSON.parse(
        localStorage.getItem("filterBannerNames") || ""
      );

      let filterArr: any[] = [];

      for (let i = 0; i < selectedParents.length; i++) {
        const filteredBanner = filterBannerNames.filter(
          (item: any) => item.parentbannername === selectedParents[i]
        );
        filteredBanner.forEach((item: any) => {
          filterArr.push(item);
        });
      }

      if (selectedParents.length === 0) {
        setBannerNames(convertToArrOfStr);
      } else {
        setBannerNames(
          filterArr.map((data: { bannername: string }) => data.bannername)
        );
      }

      setLoadingBannerNames(false);
    });
  };

  const [loadingCategories, setLoadingCategories] = useState(false);
  const loadCategories = async () => {
    await getCategories({
      categories: "",
    }).then((res) => {
      // Items for dashboard Filters
      const convertToArrOfStr = res.map(
        (data: { category: any }) => data.category
      );
      setCategories(convertToArrOfStr);
    });
  };

  const handleParentNameChange = (
    type: "All" | "Clear" | "Add" | "Remove",
    values: string[],
    filterValues?: string[]
  ) => {
    setLoadingParentNames(true);
    setParentSearchParam(values.toString());
    if (type === "Clear") {
      onFilterChange(type, "Parent Chain Name", values);
      loadBannerNames(filterValues || []);
    }
    if (type === "All") {
      setDisabledFilters({
        ...disabledFilters,
        bannerNames: false,
        categories: false,
      });
      onFilterChange(type, "Parent Chain Name", filterValues!);
      loadBannerNames(filterValues || []);
    } else {
      setDisabledFilters({
        ...disabledFilters,
        bannerNames: false,
        categories: false,
      });
      if (type === "Remove") {
        const getIndex = filterValues!.indexOf(values[0]);
        const removeItem = filterValues!.splice(getIndex, 1);
        onFilterChange(type, "Parent Chain Name", values);
      } else if (type === "Add") {
        filterValues?.push(values[0]);
        onFilterChange(type, "Parent Chain Name", filterValues!);
      }
      loadBannerNames(filterValues || []);
    }
    setTimeout(() => {
      setLoadingParentNames(false);
    }, 500);
  };

  const handleBannerNamesChange = (
    type: "All" | "Clear" | "Add" | "Remove",
    values: string[],
    filterValues?: string[]
  ) => {
    setLoadingBannerNames(true);
    setBannerSearchParam(values.toString());
    if (type === "Clear") {
      onFilterChange(type, "Chain Name", values);
    }
    if (type === "All") {
      setDisabledFilters({
        ...disabledFilters,
        categories: false,
      });
      onFilterChange(type, "Chain Name", filterValues!);
    } else {
      setDisabledFilters({
        ...disabledFilters,
        categories: false,
      });
      if (type === "Remove") {
        /*  const getIndex = filterValues!.indexOf(values[0]);
        const removeItem = filterValues!.splice(getIndex, 1); */
        onFilterChange(type, "Chain Name", values);
      } else if (type === "Add") {
        filterValues?.push(values[0]);
        onFilterChange(type, "Chain Name", filterValues!);
      }
    }
    setTimeout(() => {
      setLoadingBannerNames(false);
    }, 500);
  };

  const handleCategoryChange = (
    type: "All" | "Clear" | "Add" | "Remove",
    values: string[],
    filterValues?: string[]
  ) => {
    setLoadingCategories(true);
    setCategorySearchParam(values.toString());
    if (type === "Clear") {
      onFilterChange(type, "Category", values);
    }
    if (type === "All") {
      setDisabledFilters({
        ...disabledFilters,
        bannerNames: false,
        parentNames: false,
      });
      onFilterChange(type, "Category", filterValues!);
    } else {
      setDisabledFilters({
        ...disabledFilters,
        bannerNames: false,
        parentNames: false,
      });
    }
    if (type === "Remove") {
      /*   const getIndex = filterValues!.indexOf(values[0]);
      const removeItem = filterValues!.splice(getIndex, 1); */
      onFilterChange(type, "Category", values);
    } else if (type === "Add") {
      filterValues?.push(values[0]);
      onFilterChange(type, "Category", filterValues!);
    }
    setTimeout(() => {
      setLoadingCategories(false);
    }, 500);
  };

  useEffect(() => {
    const awaitCall = setTimeout(() => {
      Promise.all([
        loadParentCompanies(),
        loadCategories(),
        loadBannerNames([]),
      ]);
    }, 900);

    return () => {
      clearTimeout(awaitCall);
    };
  }, []);

  return (
    <Stack
      width="full"
      direction={["row", "row"]}
      mt="4"
      bg="white"
      rounded="sm"
      shadow="md"
      p={{ base: 2, md: 3 }}
    >
      <MultiSelectInput
        options={parentNames}
        onChange={handleParentNameChange}
        label="Parent"
        pluralLabel="Parent Companies"
        width={"25%"}
        isDisabled={disabledFilters.parentNames}
        loading={loadingParentNames}
        filterLabel={parentSearchParam}
      />
      <MultiSelectInput
        options={bannerNames}
        onChange={handleBannerNamesChange}
        label="Banner"
        pluralLabel="Banners"
        width={"25%"}
        isDisabled={disabledFilters.bannerNames}
        loading={loadingBannerNames}
        filterLabel={bannerSearchParam}
      />

      <MultiSelectInput
        options={categories}
        onChange={handleCategoryChange}
        label="Category"
        pluralLabel="Categories"
        width={"25%"}
        isDisabled={disabledFilters.categories}
        loading={loadingCategories}
        filterLabel={categorySearchParam}
      />
    </Stack>
  );
};

export const StoreFilter = React.memo(StoreFilterMemo);