import { Heading, Stack } from "@chakra-ui/react";
import { PageNav } from "../../components/PageNav";
import { HOME_PAGE, STORE_TRACKER_PAGE } from "../../config/pageRoutes";
import Dashboards from "./Dashboards/Dashboards";

export const StoreTrackerPage = () => {
  return (
    <Stack bg="white" pt="5" width="100%" gap="5" px={10}>
      <PageNav
        links={[
          { title: "Home", path: HOME_PAGE },
          {
            title: "Store Intelligence",
            active: true,
            path: STORE_TRACKER_PAGE,
          },
        ]}
      />

      <Heading fontSize="3xl">Store Intelligence Platform</Heading>
      <Dashboards />
    </Stack>
  );
};
