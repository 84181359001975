import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export async function makeRequest(
  storeDataType: string,
  bannerName: string,
  startDate: string,
  endDate: string,
  parentName: string
) {
  const response = await axios.get(
    /* `https://sip-api.coresight.com/store-data?table=${storeDataType}&state=&bannername=${bannerName}&start_date=${startDate}&end_date=${endDate}` */
    `https://sip-api.coresight.com/store-data?table=${storeDataType}&state=&parentname=${parentName}&bannername=${bannerName}&start_date=${startDate}&end_date=${endDate}`
  );
  return response.data;
}