import { useState } from "react";
import { Box, Button, ButtonGroup } from "@chakra-ui/react";

interface DashboardTabsProps {
  titles: string[];
  contents: JSX.Element[];
  activeTab?: number;
}

export default function Tabs({
  titles,
  contents,
  activeTab = 0,
}: DashboardTabsProps) {
  const isValidTab = activeTab >= 0 && activeTab < titles.length;

  const [currentTab, setCurrentTab] = useState<number>(
    isValidTab ? activeTab : 0
  );
  const handleClick = (index: number) => {
    setCurrentTab(index);
  };
  return (
    <Box>
      <ButtonGroup size="sm" isAttached variant="outline">
        {/* Tabs Container */}
        {titles.map((title, index) => (
          <Button
            isActive={currentTab === index}
            onClick={() => handleClick(index)}
            key={index}
            variant="outline"
            size="md"
            fontWeight="normal"
          >
            {title}
          </Button>
        ))}
      </ButtonGroup>
      {/* Tabs Container */}
      <Box>
        {contents.map((content, index) => currentTab === index && content)}
      </Box>
    </Box>
  );
}
