import { Heading } from "@chakra-ui/react";
import { storeOpening } from "../../config/dashboardConfigs";
import DashboardTabs from "./components/Tabs";
import DashboardFloatingMenu from "./components/DashboardFloatingMenu";
import SendRequestForm from "../../components/SubmitRequestForm";
import TestDashboard from "./components/testDashboard";

export const StoreOpeningPage = () => {
  const tabs = [
    {
      title: storeOpening[0].title,
      content: (
        <>
          <TestDashboard
            containerID={storeOpening[0].title + Math.random()}
            src={storeOpening[0].src}
            Filters={storeOpening[0].filters}
          />
          <SendRequestForm />

          <DashboardFloatingMenu
            jumpToFilterID="store-intelligence-platform-heading"
            jumpToDownloadID="submit-request-header"
          />
        </>
      ),
    },

    {
      title: storeOpening[1].title,
      content: (
        <>
          <TestDashboard
            containerID={storeOpening[1].title + Math.random()}
            src={storeOpening[1].src}
            Filters={storeOpening[0].filters}
          />
          <SendRequestForm />
          <DashboardFloatingMenu
            jumpToFilterID="store-intelligence-platform-heading"
            jumpToDownloadID="submit-request-header"
          />
        </>
      ),
    },
  ];
  return (
    <>
      <Heading
        size={["sm", null, null, "md"]}
        as="h2"
        textTransform="capitalize"
      >
        Store Openings
      </Heading>
      <DashboardTabs tabs={tabs} />
    </>
  );
};
