import {
  Box,
  ButtonGroup,
  Container,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { RiHome6Line } from "react-icons/ri";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { TbStack2 } from "react-icons/tb";
import { Outlet } from "react-router-dom";
import { IconType } from "react-icons/lib";

import { NavButton } from "./components/NavButton";
import DashboardFiltersProvider from "./Context/DashbarodFilterContext";
interface IMeuItem {
  title: string;
  path: string;
  Icon: IconType;
}
const menuItems: IMeuItem[] = [
  {
    title: "Store Openings",
    path: "opening",
    Icon: RiHome6Line,
  },

  {
    title: "Store Closures",
    path: "closing",
    Icon: HiOutlineChartSquareBar,
  },

  {
    title: "Active Stores",
    path: "active",
    Icon: TbStack2,
  },
];

export default function StoreIntelligencePlatformPage() {
  return (
    <DashboardFiltersProvider>
      <Box>
        <Container maxW="container.xl" minHeight={1000}>
          <Stack gap={2} mt={10}>
            <Heading
              as="h1"
              size={["md", null, null, "xl"]}
              id="store-intelligence-platform-heading"
            >
              Store Intelligence Platform
            </Heading>

            <Box paddingY={5} width="full">
              <ButtonGroup
                gap={2}
                display="flex"
                justifyContent={["center", null, "start"]}
                flexFlow={["row wrap", "row nowrap"]}
              >
                {menuItems.map(({ Icon, title, path }) => (
                  <NavButton
                    // isActive={path === activePath}
                    leftIcon={<Icon />}
                    to={path}
                    size={["sm", null, "lg"]}
                  >
                    {title}
                  </NavButton>
                ))}
              </ButtonGroup>
            </Box>

            <Outlet />
          </Stack>
        </Container>
      </Box>
    </DashboardFiltersProvider>
  );
}
