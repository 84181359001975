import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  let { user, logout } = useAuth();
  let location = useLocation();
  console.log("user", user);

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    logout();
    return (
      <Navigate
        to={{
          pathname: "/login",
          //   @ts-ignore
          state: { from: location },
          replace: true,
        }}
      />
    );
  }

  return children;
}
