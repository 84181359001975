import { Box, Button, ButtonProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

interface NavButtonProps extends ButtonProps {
  to: string;
}

export const NavButton = ({ to, ...props }: NavButtonProps) => (
  <NavLink to={to}>
    {({ isActive }) => (
      <Button
        isActive={isActive}
        size="lg"
        color="gray.600"
        justifyContent="flex-start"
        variant="solid"
        colorScheme="whiteAlpha"
        _active={{ bg: "red.500", color: "white" }}
        _hover={{ bg: "red.500", color: "white" }}
        rightIcon={<Box width={5}></Box>}
        fontWeight="medium"
        bg="white"
        {...props}
      />
    )}
  </NavLink>
);
