import { useToast } from "@chakra-ui/react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getCategories,
  getBannerNames,
  getMsaNames,
  getParentNames,
  getStates,
  getStoreNames,
} from "../../../api/filters";

interface FilterContextType {
  states: string[];
  msaNames: string[];
  bannerNames: string[];
  parentNames: string[];
  storeNames: string[];
  categories: string[];
}

const DashboardFiltersContext = createContext<FilterContextType>(
  {} as FilterContextType
);

export default function DashboardFiltersProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const toast = useToast();
  const [categories, setCategories] = useState<string[]>([]);
  const [storeNames, setStoreNames] = useState<string[]>([]);
  const [bannerNames, setBannerNames] = useState<string[]>([]);
  const [parentNames, setParentNames] = useState<string[]>([]);

  const [msaNames, setMsaNames] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);

  const loadFilters = async () => {
    try {
      // load stores
      const stores = await getStoreNames({});
      setStoreNames(stores.results.map((data) => data.storename));

      // load parent Names
      const parentNames = await getParentNames({});
      setParentNames(parentNames.results.map((data) => data.parentbannername));

      // load chain Names
      const bannerNames = await getBannerNames({});
      setBannerNames(bannerNames.results.map((data) => data.bannername));

      // load categories
      const categories = await getCategories({});
      setCategories(categories.results.map((data) => data.category));

      // load Msas
      // const msas = await getMsaNames({});
      // setMsaNames(msas.results.map((data) => data.msaname));

      // load States
      const states = await getStates({});
      setStates(states.results.map((data) => data.state));
    } catch (err: any) {
      toast({
        status: "error",
        title: `Error loading  filters`,
        description: err.message,
        variant: "subtle",
      });
    }
  };

  useEffect(() => {
    loadFilters();
  }, []);

  const memoedValue = useMemo(
    () => ({
      states,
      msaNames,
      bannerNames,
      parentNames,
      storeNames,
      categories,
    }),
    [states, msaNames, bannerNames, parentNames, storeNames, categories]
  );

  return (
    <DashboardFiltersContext.Provider value={memoedValue as FilterContextType}>
      {children}
    </DashboardFiltersContext.Provider>
  );
}

export function useDashboardFilters() {
  return useContext(DashboardFiltersContext);
}
