import { Box, Center } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import SendRequestForm from "../SubmitRequestForm";

import DashboardHeader from "./DashboardHeader";

interface DashboardProps {
  url: string;
  containerID: string;
  colorScheme: string;
  title: string;
  redirectPath?: string;
  redirectTitle?: string;
}

export default function Dashboard({
  url,
  containerID,
  colorScheme,
  title,
  redirectPath,
  redirectTitle,
}: DashboardProps) {
  const [viz, setViz] = useState<any>();

  function showDownloadDialog() {
    filterSingleValue();
    // viz?.displayDialogAsync("export-pdf");
  }

  function filterSingleValue() {
    console.log(viz.workbook);

    let sheet: any = viz.workbook.activeSheet;
    console.log(sheet.worksheets);

    for (const ws of sheet.worksheets) {
      console.log(ws);

      ws.applyFilterAsync(
        "State",
        ["Alaska"],
        window.tableau.FilterUpdateType.Replace
      );
    }
  }

  useEffect(() => {
    async function initViz() {
      const containerDiv = document.getElementById(containerID);

      if (containerDiv!.childNodes?.length < 1) {
        const viz = new window.TableauViz();
        viz.toolbar = "top";
        viz.src = url;
        viz.device = "tablet";
        console.log("DEVice", viz.device);
        containerDiv?.appendChild(viz);
        setViz(viz);
      }
    }
    initViz();
  }, []);

  return (
    <>
      <Box
        width="full"
        position="relative"
        justifyContent="center"
        display="flex"
      >
        <DashboardHeader
          title={title}
          onDownload={showDownloadDialog}
          backgroundColor={colorScheme}
          redirectPath={redirectPath}
          redirectTitle={redirectTitle}
          loadedDashboard={!!viz}
        />
        <Box
          id={containerID}
          pt={10}
          minH="1200px"
          minWidth={{ base: "100%", md: "1400px" }}
        />
      </Box>
      <SendRequestForm />
    </>
  );
}
