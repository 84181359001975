import { extendTheme } from "@chakra-ui/react";

// example theme
export const theme = extendTheme({
  colors: {
    red: {
      500: "#d62d2f",
    },

    gray: {
      50: "#F9FAFB",
    },
  },

  fonts: {
    body: `'Roboto', sans-serif`,
    heading: `'Roboto', sans-serif`,
    mono: `'Roboto', sans-serif`,
  },
});
