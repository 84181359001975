import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";

interface DateFilterProps {
  onChange: (
    startMonth: any,
    startYear: any,
    endMonth: any,
    endYear: any
  ) => void;
  /* label: string;
  name: string; */
}

export const DateFilter = ({ onChange }: DateFilterProps) => {
  const [startMonth, setStartMonth] = useState<any>();
  const [startYear, setStartYear] = useState<any>();
  const [endMonth, setEndMonth] = useState<any>();
  const [endYear, setEndYear] = useState<any>();

  const handleDateChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target;
    const date = new Date(value);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (name === "startDate") {
      setStartMonth(month);
      setStartYear(year);
    } else if (name === "endDate") {
      setEndMonth(month);
      setEndYear(year);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onChange(startMonth, startYear, endMonth, endYear);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack>
        <Box>
          <Text>Start Date</Text>
          <FormControl size="lg" isRequired>
            <Input
              onChange={handleDateChange}
              type="month"
              min="2019-06"
              max="2022-11"
              name="startDate"
              size={["sm", "md"]}
              width={["6em", "initial"]}
              marginRight={["1em", 0]}
            />
          </FormControl>
        </Box>
        <Box>
          <Text>End Date</Text>
          <FormControl size="lg" isRequired>
            <Input
              onChange={handleDateChange}
              type="month"
              min="2019-06"
              max="2022-11"
              name="endDate"
              size={["sm", "md"]}
              width={["6em", "initial"]}
              marginRight={["1em", 0]}
            />
          </FormControl>
        </Box>
        <Box>
          <Text visibility="hidden">End Date</Text>
          <Button colorScheme="red" type="submit" size={["sm", "md"]}>
            Apply
          </Button>
        </Box>
      </HStack>
    </form>
  );
};
