import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Button,
  useDisclosure,
  Input,
  Stack,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface MultiSelectInputProps {
  isDisabled?: boolean;
  options: string[];
  onChange: (
    type: "All" | "Clear" | "Add" | "Remove",
    values: string[],
    filterValues?: string[]
  ) => void;
  label: string;
  pluralLabel: string;
  width?: string | number;
  loading?: boolean;
  filterLabel?: string;
}

export const MultiSelectInput = ({
  label,
  pluralLabel,
  options,
  onChange,
  width,
  isDisabled = false,
  loading = false,
  filterLabel,
}: MultiSelectInputProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [allChecked, setAllCheked] = useState(true);
  const [optionsState, setOptionsState] = useState<string[]>(options);
  const [checkedItems, setCheckedItems] = useState(
    new Array(optionsState.length).fill(true)
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    setOptionsState(
      options.filter((option) =>
        option.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      )
    );
  };

  const handleChange = (index: number) => {
    const type = checkedItems[index] ? "Remove" : "Add";
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !checkedItems[index];

    const filterValues = optionsState.filter((_, index) => checkedItems[index]);

    onChange(type, [optionsState[index]], filterValues);
    setCheckedItems(newCheckedItems);
    type === "Remove"
      ? setAllCheked(false)
      : setAllCheked(checkedItems.every((item) => Boolean(item)));
  };

  const toggleAll = () => {
    const all = !allChecked;
    const newValues = all
      ? new Array(optionsState.length).fill(true)
      : new Array(optionsState.length).fill(false);
    setCheckedItems(newValues);
    const type = all ? "All" : "Clear";
    onChange(type, []);
    setAllCheked(all);
  };

  const getLabel = () => {
    if (loading) return "Loading...";
    if (allChecked) return "All";

    let indexOfFirstTicked: number | undefined = undefined;
    const ticked = checkedItems.filter((item, index) => {
      if (item === true) {
        if (indexOfFirstTicked === undefined) indexOfFirstTicked = index;
        return item;
      }
    });

    if (ticked.length === 0) return "None";
    else if (ticked.length === 1) return filterLabel;
    else return "Multiple values";
  };

  useEffect(() => {
    setOptionsState(options);
    setCheckedItems(new Array(options.length).fill(true));
    setAllCheked(true);
  }, [options]);
  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Stack gap={0} width={["100%", `${width}`]}>
          <Text color="gray.500" fontSize="sm">
            {label}
          </Text>
          <Button
            isDisabled={loading || isDisabled}
            justifyContent="space-between"
            fontWeight="normal"
            variant="outline"
            width={["6em", "full"]}
            onClick={onToggle}
            rightIcon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
            overflowX="hidden"
          >
            {getLabel()}
          </Button>
        </Stack>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>
          <Input size="xs" onChange={handleSearch} />
        </PopoverHeader>
        <PopoverBody overflowY="scroll" maxHeight={400}>
          <Stack spacing={[1, 5]}>
            <Checkbox onChange={toggleAll} isChecked={allChecked}>
              All
            </Checkbox>

            <Stack spacing={[1, 5]}>
              {optionsState.map((value, index) => (
                <Checkbox
                  key={`${value}${Math.random()}`}
                  isChecked={checkedItems[index]}
                  onChange={() => handleChange(index)}
                >
                  {value}
                </Checkbox>
              ))}
            </Stack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
