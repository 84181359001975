import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INITIAL_PAGE_AFTER_LOGIN } from "../../config/pageRoutes";
import { useAuth } from "../../context/AuthContext";
import loginBg from "./assets/loginBg.png";
import logoRed from "./assets/logoRed.png";
import logoDimRed from "./assets/logoDimRed.png";
import { HashLink } from "react-router-hash-link";

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { login, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  // @ts-ignore
  const from = location.state?.from || INITIAL_PAGE_AFTER_LOGIN;

  function handleSubmit(event: any) {
    event.preventDefault();
    setLoading(true);
    login(email)
      .then(() => {
        toast({
          position: "top-right",
          duration: 3000,
          title: "Login Successful",
          status: "success",
        });
        setLoading(false);
        // Redirect the user to where he came from
        navigate(from, { replace: true });
      })
      .catch((err) => {
        let errorMessage = err.message || err.response?.data?.message;
        // if (err.response.status === 400)
        //   errorMessage = "Incorrect email or password";
        setLoading(false);
        toast({
          position: "top-right",
          duration: 3000,
          title: "Error Logging In",
          description: errorMessage || "Error Logging in contact admin",
          status: "error",
        });

        logout();
      });
  }

  return (
    <Center
      height="100vh"
      width="100vw"
      backgroundImage={`url(${logoRed}), url(${loginBg})`}
      backgroundPosition={["95% 95% , center", "95% 90% , center"]}
      backgroundRepeat="no-repeat"
      backgroundSize="170px, cover"
      backgroundAttachment="fixed"
      overflowY="scroll"
    >
      <Center
        width={["80%", "60%", "95%", "900px"]}
        height={["500px", null, "302px"]}
        background="#ffff"
        borderRadius="10px"
        backgroundImage={`url(${logoDimRed})`}
        backgroundPosition={["10% 10%", null, "-10% 90%"]}
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        marginTop={["-7em", null, "-12em"]}
      >
        <Box as="form" onSubmit={handleSubmit} height="full">
          <Flex
            width="full"
            height="full"
            px={0}
            mx={0}
            flexDirection={["column", null, "row"]}
            justifyContent="space-evenly"
            alignItems="center"
            gap={["0", null, "36"]}
          >
            <VStack marginTop="0em">
              <Text
                as="h1"
                fontSize={["30px", null, "40px"]}
                fontWeight="700"
                lineHeight="40px"
              >
                Store <br /> Intelligence <br /> Platform
                <Text as="hr" border="1px solid #D64C2E" />
                <Text
                  fontSize="12px"
                  fontWeight="600"
                  color="red.500"
                  marginTop="-2"
                >
                  BY CORESIGHT RESEARCH
                </Text>
              </Text>
            </VStack>

            <VStack spacing="5">
              <Text color="red.500" textAlign="center" fontWeight="400">
                LOG IN
              </Text>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                width={["full", null, "318px"]}
                height="42px"
                type="email"
              />
              <Button
                type="submit"
                isLoading={loading}
                isDisabled={loading}
                loadingText="Logging In..."
                colorScheme="red"
                width={["full", null, "318px"]}
                height="42px"
                fontWeight="400"
              >
                Continue
              </Button>
              <Box color="#0F78A0" width="full">
                <HashLink to="/#footer-hash-link" smooth>
                  Contact Us
                </HashLink>
              </Box>
            </VStack>
          </Flex>
        </Box>
      </Center>
    </Center>
  );
};
