import { Button} from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";

function FloatMenuButton({
  buttonText,
  jumpToHashLink,
  id,
  icon,
}: {
  buttonText?: string;
  jumpToHashLink?: string;
  id?: string;
  icon?: any;
}) {
  return (
    <HashLink smooth to={`#${jumpToHashLink}`}>
      <Button
        size="sm"
        borderRadius="8px"
        border="1px solid #D0D5DD"
        boxShadow="0px 1px 2px rgba(16, 24, 40, 0.5 )"
        backgroundColor="#fff"
        fontWeight="500"
        fontSize="14px"
        color={"gray.700"}
        id={id}
        leftIcon={icon}
      >
        {buttonText}
      </Button>
    </HashLink>
  );
}

export default FloatMenuButton;
