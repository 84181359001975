import {
  Heading,
  HStack,
  Link,
  Stack,
  Text,
  Center,
  Flex,
  Box,
  Divider,
  Image,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";
import { useEffect } from "react";
import FooterLinks from "./FooterLink";

export default function Footer() {
  useEffect(() => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "9302266",
      formId: "00c131ca-5019-4bd9-a473-4e1ecedf1b42",
      target: "#footer_feedback_form",
    });
  }, []);
  return (
    <Box height="auto" background="#26272A" id="footer-hash-link">
      <Center
        height={["100px", "30px"]}
        width="100%"
        paddingTop={["2em", null, null, "0"]}
      >
        <Text
          color="gray.200"
          paddingTop={["1em", null, null, "0"]}
          paddingX="1em"
          textAlign="justify"
        >
          <Text as="strong" color="gray.50">
            Important : &nbsp;
          </Text>
          Certain Coresight data results are from calculations that use data
          elements licensed from third parties, including ChainXY
        </Text>
      </Center>

      <Flex
        flexDirection={["column", "row", "row", "row"]}
        justifyContent={["space-between", null, "space-around"]}
        alignItems={["center", null, "start", "start"]}
        marginTop={["2em", null, "4em"]}
        color="#fff"
        paddingX={["0em", "1em", "4em"]}
        gap={["3em", null, "0em"]}
      >
        <Stack width="20em">
          <Image
            paddingRight={5}
            marginTop={-2}
            minWidth="150px"
            maxWidth="180px"
            src="https://coresight.com/wp-content/uploads/2019/04/logo-red.png"
            alt="logo"
          />
          <br />
          <Text>
            Register for a free user account to access select reports and
            infographics as well as Weinswig’s Weekly. Click here to register.
          </Text>
          <br />
          <Text>
            Click{" "}
            <Link color="red" href="https://coresight.com/register/member/">
              here
            </Link>{" "}
            to register.
          </Text>
          <Link href="mailto: media@coresight.com">
            <Button size="md" borderRadius="none" colorScheme="red" width="8em">
              Media Inquiry
            </Button>
          </Link>
        </Stack>
        <Stack width="20em" height="auto">
          <Heading size="md">Quick Links</Heading>
          <br />
          {[
            {
              title: "US Consumer Trackers",
              link: "https://coresight.com/us-consumer-tracker-coresight-research-survey-findings/",
            },
            {
              title: "Market Outlooks",
              link: "https://coresight.com/category/research/?fwp_research_products=sector-overviews",
            },
            {
              title: "Single's Day",
              link: "https://coresight.com/singles-day-the-11-11-global-shopping-festival/",
            },
            {
              title: "Weekly Store Trackers",
              link: "https://coresight.com/weekly-store-trackers/",
            },
            {
              title: "Free Reports and Data Graphics",
              link: "https://coresight.com/free-retail-industry-reports/",
            },
            {
              title: "Digital Events Library",
              link: "https://coresight.com/videos/",
            },
          ].map(({ title, link }) => (
            <FooterLinks title={title} link={link} />
          ))}
        </Stack>
        <Stack width="20em">
          <Heading size="md">Contact Us</Heading>
          <br />
          <Box id="footer_feedback_form"></Box>
        </Stack>
      </Flex>
      <Center>
        <Divider orientation="horizontal" borderColor="#98A2B3" width="82%" />
      </Center>
      <Flex
        color="#98A2B3"
        justifyContent={["space-around", null, "space-around"]}
        flexDirection={["column", null, "row"]}
        paddingY={["1em", null, "1em"]}
        paddingX={["2em", "1em", "4em"]}
        gap={["1em", null, "0em"]}
      >
        <Text letterSpacing={["2px", null, "2px"]}>
          Copyright © 2022 by CORESIGHT RESEARCH. All Rights Reserved
        </Text>

        <HStack spacing="4">
          <Link href="https://www.facebook.com/CoresightResearch" isExternal>
            <Icon as={FaFacebook} boxSize="6" />
          </Link>

          <Link href="https://twitter.com/coresightnews" isExternal>
            <Icon as={FaTwitter} boxSize="6" />
          </Link>

          <Link href="https://coresight.com/register/member/#" isExternal>
            <Icon as={AiFillWechat} boxSize="6" />
          </Link>

          <Link
            href="https://www.linkedin.com/company/coresight-research/"
            isExternal
          >
            <Icon as={FaLinkedin} boxSize="6" />
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
}
