import { Stack } from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { getMsaNames, getLocations } from "../../../api/filters";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { MultiSelectInput } from "./MultiSelectInput";

interface LocationFilterProps {
  onFilterChange: (
    type: "All" | "Clear" | "Add" | "Remove",
    name: string,
    values: string[]
  ) => void;
}

const LocationFilterMemo = ({ onFilterChange }: LocationFilterProps) => {
  const [states, setStates] = useState<string[]>([]);
  const [msaNames, setMsaNames] = useState<string[]>([]);
  const [locationSearchParam, setLocationSearchParam] = useState("");
  const [msaSearchParam, setMsaSearchParam] = useState("");

  const [parentNameDFS, setParentNameDFS] = useLocalStorage("filterMsa", []);

  const [loadingStates, setLoadingStates] = useState(false);
  const loadStates = async () => {
    await getLocations({}).then((res) => {
      // Items for dashboard Filters
      const convertToArrOfStr = res.results.map(
        (data: { state: any }) => data.state
      );
      setStates(convertToArrOfStr);
    });
  };

  const [loadingMsas, setLoadingMsas] = useState(false);
  const loadMsaNames = async (selectedStates: string[]) => {
    setLoadingMsas(true);

    await getMsaNames({}).then((res) => {
      //Items for dashboard filters sorting
      setParentNameDFS(res);

      // Items for dashboard filters drop-down list
      const convertToArrOfStr = res.map(
        (data: { msaname: any }) => data.msaname
      );

      const filterMsa = JSON.parse(localStorage.getItem("filterMsa") || "");

      let filterArr: any[] = [];

      for (let i = 0; i < selectedStates.length; i++) {
        const filteredMsa = filterMsa.filter(
          (item: any) => item.state === selectedStates[i]
        );
        filteredMsa.forEach((item: any) => {
          filterArr.push(item);
        });
      }

      if (selectedStates.length === 0) {
        setMsaNames(convertToArrOfStr);
      } else {
        setMsaNames(filterArr.map((data: { msaname: any }) => data.msaname));
      }

      setLoadingMsas(false);
    });
  };

  const handleStateChange = (
    type: "All" | "Clear" | "Add" | "Remove",
    values: string[],
    filterValues?: string[]
  ) => {
    setLoadingStates(true);
    setLocationSearchParam(values.toString());
    if (type === "Clear") {
      onFilterChange(type, "State", values);
    }
    if (type === "All") {
      onFilterChange(type, "State", filterValues!);
      loadMsaNames(filterValues || []);
    } else {
      if (type === "Remove") {
        const getIndex = filterValues!.indexOf(values[0]);
        const removeItem = filterValues!.splice(getIndex, 1);
        onFilterChange(type, "State", values);
      } else if (type === "Add") {
        filterValues?.push(values[0]);
        onFilterChange(type, "State", filterValues!);
      }
      loadMsaNames(filterValues || []);
    }
    setTimeout(() => {
      setLoadingStates(false);
    }, 500);
  };

  const handleMsaChange = (
    type: "All" | "Clear" | "Add" | "Remove",
    values: string[],
    filterValues?: string[]
  ) => {
    setLoadingMsas(true);
    setMsaSearchParam(values.toString());
    if (type === "Clear") {
      onFilterChange(type, "Msa Name", values);
    }
    if (type === "All") {
      onFilterChange(type, "Msa Name", filterValues!);
    } else {
      if (type === "Remove") {
        const getIndex = filterValues!.indexOf(values[0]);
        const removeItem = filterValues!.splice(getIndex, 1);
        onFilterChange(type, "Msa Name", values);
      } else if (type === "Add") {
        filterValues?.push(values[0]);
        onFilterChange(type, "Msa Name", filterValues!);
      }
    }
    setTimeout(() => {
      setLoadingMsas(false);
    }, 500);
  };

  useEffect(() => {
    const awaitCall = setTimeout(() => {
      Promise.all([loadStates(), loadMsaNames([])]);
    }, 900);

    return () => {
      clearTimeout(awaitCall);
    };
  }, []);

  return (
    <Stack
      direction={["row", "row"]}
      mt="4"
      bg="white"
      rounded="sm"
      shadow="md"
      width="full"
      p={{ base: 2, md: 3 }}
    >
      <MultiSelectInput
        options={states}
        onChange={handleStateChange}
        label="State"
        pluralLabel="States"
        width={"25%"}
        loading={loadingStates}
        filterLabel={locationSearchParam}
      />

      <MultiSelectInput
        options={msaNames}
        onChange={handleMsaChange}
        label="Msa"
        pluralLabel="Msas"
        width={"25%"}
        loading={loadingMsas}
        filterLabel={msaSearchParam}
      />
    </Stack>
  );
};

export const LocationFilter = React.memo(LocationFilterMemo);
