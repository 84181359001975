import { useState } from "react";
import { Box, VStack } from "@chakra-ui/react";
import FloatMenuButton from "./FloatMenuButton";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { FiDownload, FiFilter } from "react-icons/fi";

interface DashboardFloatingMenuProps {
  jumpToFilterID?: string;
  jumpToDownloadID?: string;
}

function DashboardFloatingMenu(props: DashboardFloatingMenuProps) {
  const [toggleMenu, setToggleMenu] = useState(true);

  const handleHide = () => {
    setToggleMenu(!toggleMenu);
    const floatButton1 = document.getElementById("floatButton1");
    const floatButton2 = document.getElementById("floatButton2");
    floatButton1!.style.visibility = "hidden";
    floatButton2!.style.visibility = "hidden";
  };

  const handleShow = () => {
    setToggleMenu(!toggleMenu);
    const floatButton1 = document.getElementById("floatButton1");
    const floatButton2 = document.getElementById("floatButton2");
    floatButton1!.style.visibility = "visible";
    floatButton2!.style.visibility = "visible";
  };

  return (
    <VStack zIndex="10" position="sticky" bottom="0" width="90vw">
      <VStack
        width="10em"
        height="8em"
        alignItems="end"
        marginLeft="auto"
        paddingRight={["0em", "0.5em"]}
      >
        <>
          <FloatMenuButton
            buttonText={"Filter"}
            jumpToHashLink={props.jumpToFilterID}
            id="floatButton1"
            icon={<FiFilter />}
          />
          <FloatMenuButton
            buttonText={"Download Data"}
            jumpToHashLink={props.jumpToDownloadID}
            id="floatButton2"
            icon={<FiDownload />}
          />
        </>
        <Box
          color="#F04438"
          fontSize="2em"
          cursor="pointer"
          position="absolute"
          top="60%"
        >
          {toggleMenu ? (
            <AiFillCloseCircle onClick={handleHide} />
          ) : (
            <IoIosArrowDropupCircle onClick={handleShow} />
          )}
        </Box>
      </VStack>
    </VStack>
  );
}

export default DashboardFloatingMenu;
