import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { NavBar } from "./components/Layout/Navbar";
import { pageRoutes } from "./config/pageRoutes";
import { theme } from "./config/theme";
import AuthProvider from "./context/AuthContext";

declare global {
  interface Window {
    pendo: any;
    TableauViz: any;
    hbspt: any;
    tableau: any;
  }
}

export const App = () => (
  <BrowserRouter basename={"/storefront"}>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <NavBar>
          <Routes>
            {pageRoutes.map(({ path, element, subRoutes }, index) => (
              <Route key={index} path={path} element={element}>
                {subRoutes &&
                  subRoutes.map((subRoute) => (
                    <Route
                      key={subRoute.path}
                      path={subRoute.path}
                      element={subRoute.element}
                    />
                  ))}
              </Route>
            ))}
          </Routes>
        </NavBar>
      </AuthProvider>
    </ChakraProvider>
  </BrowserRouter>
);
